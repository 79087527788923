import React from "react";
import { useNavigate } from "react-router-dom";

const ProjectCard = ({ project }) => {
  const navigate = useNavigate();

  const navigateToProject = (id) => {
    navigate(`/project/${id}`);
  };

  return (
    <div
      className="card project-card"
      onClick={() => navigateToProject(project.id)}
    >
      <div
        className="project-image"
        style={{ backgroundImage: `url(${project.image})` }}
      >
        <div className="project-overlay">
          <h4 className="project-category">{project.topic}</h4>
          <h2 className="project-overlay-title ">{project.title}</h2>
          <p className="project-description">{project.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
