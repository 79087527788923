import React, { useEffect } from "react";
import "./timeline.css";

function Timeline() {
  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in");
          } else {
            entry.target.classList.remove("fade-in");
          }
        });
      },
      { threshold: 0.1 }
    );

    const items = document.querySelectorAll(".content-item");
    items.forEach((item) => {
      observer.observe(item);
    });

    return () => {
      items.forEach((item) => {
        observer.unobserve(item);
      });
    };
  }, []);

  return (
    <div className="timeline-container container">
      <div className="border border-success p-3">
        <h2 className="text-center">Project Timeline</h2>

        <div className="timeline row justify-content-center align-items-center">
          <div
            className="timeline-step col-6 col-md-2 text-center"
            onClick={() => handleScrollTo("discovery")}
          >
            <span>Discovery</span>
          </div>
          <div className="col-1 col-md-1 text-center">→</div>
          <div
            className="timeline-step col-6 col-md-2 text-center"
            onClick={() => handleScrollTo("design")}
          >
            <span>Design</span>
          </div>
          <div className="col-1 col-md-1 text-center">→</div>
          <div
            className="timeline-step col-6 col-md-2 text-center"
            onClick={() => handleScrollTo("prototypes")}
          >
            <span>Prototypes</span>
          </div>
          {/* <div className="col-1 col-md-1 text-center">→</div> */}
          {/* <div
            className="timeline-step col-6 col-md-2 text-center"
            onClick={() => handleScrollTo("build")}
          >
            <span>Build</span>
          </div> */}
          {/* <div className="col-1 col-md-1 text-center">→</div>
          <div
            className="timeline-step col-6 col-md-2 text-center"
            onClick={() => handleScrollTo("more-user-testing")}
          >
            <span>User Testing</span>
          </div> */}
          {/* <div className="col-1 col-md-1 text-center">→</div> */}
          {/* <div
            className="timeline-step col-6 col-md-2 text-center"
            onClick={() => handleScrollTo("launch")}
          >
            <span>Documentation</span>
          </div> */}
        </div>
      </div>
      <div className="timeline-content">
        <div className="content-item" id="discovery">
          <h3>Discovery</h3>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              className="regular-text"
              style={{
                maxWidth: "85%",
                marginTop: "5rem",
                fontSize: "20px",
              }}
            >
              We focused on understanding both the practical and emotional
              aspects of closet organization and personal storage. We explored
              how closets function not only as storage spaces but also as key
              elements in daily routines, personal identity, and privacy. This
              involved examining how they contribute to organization, ease of
              preparation, and overall well-being.
            </p>
          </div>
        </div>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/assets/projects/amoire-benchmarking.png"
            alt="amoire-benchmarkingimage"
            style={{ maxWidth: "50%" }}
          />
          <p
            className="regular-text"
            style={{
              maxWidth: "50%",
              fontSize: "20px",
            }}
          >
            I explored several apps, including Instagram, Etsy, and Weee!, but
            found that Pinterest’s simple, image-based design best aligns with
            the concept of a closet. Unlike the other apps, which could clutter
            the user experience, Pinterest offers a clean and intuitive way to
            organize and curate content, making it the ideal platform for adding
            closet and outfit selection features for this project.
          </p>
        </div>
        <br />

        <br />
        <br />
        <br />
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        <br />
        <br />
        <div className="content-item" id="design">
          <h3>Design</h3>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              className="regular-text"
              style={{
                maxWidth: "85%",
                fontSize: "20px",
              }}
            >
              For the initial low-fi wireframes, I focused on creating a simple
              layout where users could only view the entire outfit at the end.
              This approach kept things minimal, but lacked interactivity in the
              outfit selection process
            </p>
            {/* <img
              src="/assets/projects/armoire/armoire-wireframe3.png"
              alt="amoire-sitemap"
              style={{ maxWidth: "50%", marginLeft: "2em" , maxHeight:"50rem"}}
            /> */}
          </div>
        </div>

        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/assets/projects/armoire/armoire-wireframe4.png"
            alt="amoire-sitemap"
            style={{ maxWidth: "120%" }}
          />
        </div>

        <br />
        <br />
        <br />
        <br />
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            className="regular-text"
            style={{
              maxWidth: "50%",
              fontSize: "20px",
            }}
          >
            During initial user testing, I found that users wanted to see their
            previously selected items while choosing the next piece to create a
            cohesive outfit. In response, I added a mini-viewer to display
            selected items, so users can see what best matches what they have
            already picked.
          </p>
          <img
            src="/assets/projects/armoire/armoire-wireframe5.png"
            alt="amoire-sitemap"
            style={{ height: "auto", maxWidth: "30%", marginLeft: "5em" }}
          />
        </div>

        <br />
        <br />
        <br />
        <br />

        <div className="content-item" id="prototypes">
          <h3>Prototypes</h3>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <div className="col">
              <img
                src="/assets/projects/juz-delights-wireframes.png"
                alt="Juz Delights Wireframe"
                className="banner-image"
              />
            </div> */}
            {/* <div className="col"> */}
            <img
              src="/assets/projects/armoire/armoire-prototype.png"
              alt="Juz Delights Wireframe"
              className="banner-image"
              style={{ maxWidth: "80%" }}
            />

            {/* </div> */}
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="download-buttons">
          
              <button
                className="download-btn"
                onClick={() =>
                  (window.location.href = "/assets/projects/armoire/Armoire-Project-Overview.pptx")
                }
              >
                Project Overview
              </button>
              <button
                className="download-btn"
                onClick={() =>
                  (window.location.href = "/assets/projects/armoire/Armoire-Process-Deck.pptx")
                }
              >
                Process Deck
              </button>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default Timeline;
