import React from "react";
import { useParams } from "react-router-dom";
import JuzDelights from "./projectDetails/JuzDelights/JuzDelgihts";
import Collide from "./projectDetails/Collide/Collide";
import MovieMates from "./projectDetails/MovieMates/MovieMates";
import TsengLab from "./projectDetails/TsengLab";
import StudentHub from "./projectDetails/StudentHub/StudentHub";
import NeighborhoodNav from "./projectDetails/Neighborhood/Neighborhood";
import Armoire from "./projectDetails/Armoire/Armoire"

const projects = [
  {
    id: "Armoire",
    title: "",
    topic: "Web Development",
    component: Armoire,
  },
  {
    id: "JuzDelights",
    title: "",
    topic: "Web Development",
    component: JuzDelights,
  },
  {
    id: "Collide",
    title: "",
    topic: "Web Development",
    component: Collide,
  },
  {
    id: "MovieMates",
    title: "",
    topic: "MovieMates",
    component: MovieMates,
  },
  {
    id: "TsengLab",
    title: "",
    topic: "TsengLab",
    component: TsengLab,
  },
  {
    id: "StudentHub",
    title: "",
    topic: "StudentHubRedesign",
    component: StudentHub,
  },
  {
    id: "NeighborhoodNav",
    title: "",
    topic: "Neighborhood_Nav",
    component: NeighborhoodNav,
  },
];

function ProjectPage() {
  const { id } = useParams();
  const project = projects.find((p) => p.id === id);

  if (!project) {
    return <div>Project not found</div>;
  }

  const ProjectComponent = project.component;
  return (
    <div className={`project-page ${project.style}`}>
      {/* <h1>{project.title}</h1> */}
      <ProjectComponent />
    </div>
  );
}
export default ProjectPage;
